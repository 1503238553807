var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"content-header"},[_c('b-col',{staticClass:"content-header-left mb-2",attrs:{"cols":"12","md":"12"}},[_c('b-row',{staticClass:"breadcrumbs-top"},[_c('b-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"custom-header-title"},[_vm._v(" Edit Item ")]),_c('p',{staticClass:"log_info"},[_vm._v(" Created by "+_vm._s(_vm.inventoryItem.createdBy ? _vm.inventoryItem.createdBy.name : '')+" on "+_vm._s(_vm.dateFormatWithTime(_vm.inventoryItem.createdAt))),_c('br'),_vm._v("Last updated on "+_vm._s(_vm.dateFormatWithTime(_vm.inventoryItem.updatedAt))+" ")])])],1)],1)],1),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm($event)}}},[_c('validation-observer',{ref:"inventoryItemUpdateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Item Details ")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{staticClass:"my-2",attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Item Image*","label-cols-md":"3","label-for":"image"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('div',{staticClass:"image__block"},[_c('b-img',{staticClass:"mb-1 mb-sm-0 user__avatar img-preview-block",attrs:{"height":"100","src":_vm.fileSrc ? _vm.fileSrc : _vm.defaultImage}})],1),_c('div',{staticClass:"upload__buttons my-auto mx-2"},[_c('b-form-file',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/jpeg, image/jpg, image/png"},on:{"input":_vm.setImage},model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}}),_c('b-button',{staticClass:"mr-50",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$refs.fileInput.$el.childNodes[0].click()}}},[_vm._v(" Update ")]),(_vm.fileSrc)?_c('b-button',{staticClass:"mr-50",attrs:{"variant":"outline-primary"},on:{"click":function($event){return _vm.removeFileSrc()}}},[_vm._v(" Remove ")]):_vm._e()],1)])])],1),_c('b-col',{attrs:{"md":"6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Item Name*","label-cols-md":"3","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Item Name","vid":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":(errors.length > 0) ? false : null,"name":"name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.nameValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.nameError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}}),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"SKU*","label-cols-md":"3","label-for":"sku"}},[_c('validation-provider',{attrs:{"name":"SKU","vid":"sku","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"sku","state":(errors.length > 0) ? false : null,"name":"sku","placeholder":"Input SKU number, e.g. URN0-LAVENDER-CRST-LIULI"},model:{value:(_vm.sku),callback:function ($$v) {_vm.sku=$$v},expression:"sku"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.skuValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.skuError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Category ")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"category","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Category","label-for":"category","label-cols-md":"3","state":(errors.length > 0) ? false : null}},[_c('v-select',{attrs:{"id":"category","label":"name","placeholder":"Select from list","options":_vm.categories,"reduce":function (name) { return name._id; },"clearable":false},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.categoryValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.categoryError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal.modal-create-new-category",modifiers:{"modal-create-new-category":true}}],staticClass:"pl-0",attrs:{"type":"button","variant":"flat-primary"}},[_vm._v(" Manage Categories ")])],1),_c('b-col',{attrs:{"cols":"md-4"}}),_c('b-col',{attrs:{"cols":"md-6"}},[_c('validation-provider',{attrs:{"name":"brand","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Brand","label-for":"brand","label-cols-md":"3","state":(errors.length > 0) ? false : null}},[_c('b-form-input',{attrs:{"id":"brand","state":(errors.length > 0) ? false : null,"name":"brand","placeholder":"Brand Name"},model:{value:(_vm.brand),callback:function ($$v) {_vm.brand=$$v},expression:"brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.brandValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.brandError)+" ")]):_vm._e()],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"primary-button-with-background mr-3",attrs:{"type":"button","variant":"flat-primary"}},[_c('feather-icon',{attrs:{"icon":"GridIcon","size":"20"}})],1),_c('h3',{staticClass:"align-middle mr-auto"},[_vm._v(" Low Stock Warning ")])]},proxy:true}],null,true)},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Minimum Stock","label-cols-md":"3","label-for":"minStock"}},[_c('validation-provider',{attrs:{"name":"Minimum Stock","vid":"minimum-stock","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"minStock","state":(errors.length > 0) ? false : null,"name":"minStock","placeholder":"E.g. 5. Leave empty if warning is unnecessary"},model:{value:(_vm.minStock),callback:function ($$v) {_vm.minStock=$$v},expression:"minStock"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.minStockValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.minStockError)+" ")]):_vm._e()]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"md-6"}})],1)],1),_c('b-nav',{staticClass:"wrap-border save-nav"},[_c('li',{staticClass:"nav-item ml-auto"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"flat-primary"},on:{"click":_vm.cancelRedirect}},[_vm._v(" Cancel ")])],1),_c('li',{staticClass:"nav-item mr-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"success","disabled":invalid || _vm.fileSrc == null || _vm.fileSrc == ''}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save Changes")])],1)],1)])]}}])})],1),_c('b-modal',{ref:"create-new-category-modal",attrs:{"id":"modal-create-new-category","modal-class":"status-update-modal","centered":"","no-stacking":"","no-close-on-backdrop":"","header-class":"front-modal","title":"Create New Category"},on:{"ok":_vm.submitCreateNewCategory,"cancel":_vm.closeCreateNewCategoryModal},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
var cancel = ref.cancel;
return [_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":function($event){return cancel()}}},[_vm._v(" Cancel ")]),_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return ok()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon","size":"16"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Save")])],1)]}}])},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitCreateNewCategory($event)}}},[_c('validation-observer',{ref:"registerNewItemModalForm"},[_c('b-form-group',{attrs:{"label":"Category Name*","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Category Name","vid":"catName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"catName","state":(errors.length > 0 || _vm.catNameValidation) ? false : null,"name":"name","placeholder":"Category Name"},on:{"input":function($event){_vm.catNameValidation == true ? _vm.catNameValidation = false : null}},model:{value:(_vm.catName),callback:function ($$v) {_vm.catName=$$v},expression:"catName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.catNameValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.catNameError)+" ")]):_vm._e()]}}])})],1),_c('b-form-group',{attrs:{"label":"Remarks","label-for":"remarks"}},[_c('validation-provider',{attrs:{"name":"Remarks","vid":"remarks","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{staticClass:"ml-0",attrs:{"id":"remarks","name":"remarks","rows":"3","placeholder":"Remarks"},on:{"input":function($event){_vm.remarksValidation == true ? _vm.remarksValidation = false : null}},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.remarksValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.remarksError)+" ")]):_vm._e()]}}])})],1),_c('validation-provider',{attrs:{"name":"Status","vid":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Status*","label-for":"h-team-status","state":(errors.length > 0 || _vm.statusValidation) ? false : null}},[_c('v-select',{attrs:{"id":"h-team-status","label":"title","options":_vm.option,"reduce":function (title) { return title.code; },"clearable":false},on:{"input":function($event){_vm.statusValidation == true ? _vm.statusValidation = false : null}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))]),(_vm.statusValidation)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.statusError)+" ")]):_vm._e()],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }