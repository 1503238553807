<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title">
              Edit Item
            </h1>
            <p class="log_info">
              Created by {{ inventoryItem.createdBy ? inventoryItem.createdBy.name : '' }} on {{ dateFormatWithTime(inventoryItem.createdAt) }}<br>Last updated on {{ dateFormatWithTime(inventoryItem.updatedAt) }}
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-form @submit.prevent="submitForm">
      <validation-observer
        ref="inventoryItemUpdateForm"
        #default="{invalid}"
      >
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Item Details
            </h3>
          </template>
          <b-row>
            <b-col
              md="6"
              class="my-2"
            >
              <b-form-group
                label="Item Image*"
                label-cols-md="3"
                label-for="image"
              >
                <div class="d-flex justify-content-start">
                  <div
                    class="image__block"
                  >
                    <b-img
                      class="mb-1 mb-sm-0 user__avatar img-preview-block"
                      height="100"
                      :src="fileSrc ? fileSrc : defaultImage"
                    />
                  </div>

                  <div
                    class="upload__buttons my-auto mx-2"
                  >
                    <b-form-file
                      ref="fileInput"
                      v-model="image"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      style="display: none"
                      @input="setImage"
                    />
                    <b-button
                      variant="primary"
                      class="mr-50"
                      @click="$refs.fileInput.$el.childNodes[0].click()"
                    >
                      Update
                    </b-button>
                    <b-button
                      v-if="fileSrc"
                      variant="outline-primary"
                      class="mr-50"
                      @click="removeFileSrc()"
                    >
                      Remove
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6" />
            <b-col md="6">
              <b-form-group
                label="Item Name*"
                label-cols-md="3"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Item Name"
                  vid="name"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="(errors.length > 0) ? false : null"
                    name="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="nameValidation"
                    class="text-danger"
                  >
                    {{ nameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
            <b-col md="6">
              <b-form-group
                label="SKU*"
                label-cols-md="3"
                label-for="sku"
              >
                <validation-provider
                  #default="{ errors }"
                  name="SKU"
                  vid="sku"
                  rules="required"
                >
                  <b-form-input
                    id="sku"
                    v-model="sku"
                    :state="(errors.length > 0) ? false : null"
                    name="sku"
                    placeholder="Input SKU number, e.g. URN0-LAVENDER-CRST-LIULI"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="skuValidation"
                    class="text-danger"
                  >
                    {{ skuError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Category
            </h3>
          </template>
          <b-row>
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="category"
                rules=""
              >
                <b-form-group
                  label="Category"
                  label-for="category"
                  label-cols-md="3"
                  :state="(errors.length > 0) ? false : null"
                >
                  <v-select
                    id="category"
                    v-model="category"
                    label="name"
                    placeholder="Select from list"
                    :options="categories"
                    :reduce="name => name._id"
                    :clearable="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="categoryValidation"
                    class="text-danger"
                  >
                    {{ categoryError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.modal-create-new-category
                type="button"
                variant="flat-primary"
                class="pl-0"
              >
                Manage Categories
              </b-button>
            </b-col>
            <b-col cols="md-4" />
            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="brand"
                rules=""
              >
                <b-form-group
                  label="Brand"
                  label-for="brand"
                  label-cols-md="3"
                  :state="(errors.length > 0) ? false : null"
                >
                  <b-form-input
                    id="brand"
                    v-model="brand"
                    :state="(errors.length > 0) ? false : null"
                    name="brand"
                    placeholder="Brand Name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="brandValidation"
                    class="text-danger"
                  >
                    {{ brandError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-card
          header-tag="header"
        >
          <template #header>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              class="primary-button-with-background mr-3"
            >
              <feather-icon
                icon="GridIcon"
                class=""
                size="20"
              />
            </b-button>
            <h3 class="align-middle mr-auto">
              Low Stock Warning
            </h3>
          </template>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Minimum Stock"
                label-cols-md="3"
                label-for="minStock"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Minimum Stock"
                  vid="minimum-stock"
                  rules="numeric"
                >
                  <b-form-input
                    id="minStock"
                    v-model="minStock"
                    :state="(errors.length > 0) ? false : null"
                    name="minStock"
                    placeholder="E.g. 5. Leave empty if warning is unnecessary"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="minStockValidation"
                    class="text-danger"
                  >
                    {{ minStockError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              @click="cancelRedirect"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid || fileSrc == null || fileSrc == ''"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Changes</span>
            </b-button>
          </li>
        </b-nav>
      </validation-observer>
    </b-form>
    <!-- create new category modal -->
    <b-modal
      id="modal-create-new-category"
      ref="create-new-category-modal"
      modal-class="status-update-modal"
      centered
      no-stacking
      no-close-on-backdrop
      header-class="front-modal"
      title="Create New Category"
      @ok="submitCreateNewCategory"
      @cancel="closeCreateNewCategoryModal"
    >
      <b-form @submit.prevent="submitCreateNewCategory">
        <validation-observer
          ref="registerNewItemModalForm"
        >
          <b-form-group
            label="Category Name*"
            label-for="name"
          >
            <validation-provider
              #default="{ errors }"
              name="Category Name"
              vid="catName"
              rules="required"
            >
              <b-form-input
                id="catName"
                v-model="catName"
                :state="(errors.length > 0 || catNameValidation) ? false : null"
                name="name"
                placeholder="Category Name"
                @input="catNameValidation == true ? catNameValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="catNameValidation"
                class="text-danger"
              >
                {{ catNameError }}
              </small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Remarks"
            label-for="remarks"
          >
            <validation-provider
              #default="{ errors }"
              name="Remarks"
              vid="remarks"
              rules=""
            >
              <b-form-textarea
                id="remarks"
                v-model="remarks"
                name="remarks"
                rows="3"
                placeholder="Remarks"
                class="ml-0"
                @input="remarksValidation == true ? remarksValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="remarksValidation"
                class="text-danger"
              >
                {{ remarksError }}
              </small>
            </validation-provider>
          </b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Status"
            vid="status"
            rules="required"
          >
            <b-form-group
              label="Status*"
              label-for="h-team-status"
              :state="(errors.length > 0 || statusValidation) ? false : null"
            >
              <v-select
                id="h-team-status"
                v-model="status"
                label="title"
                :options="option"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </b-form-group>
          </validation-provider>
        </validation-observer>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="flat-primary"
          @click="cancel()"
        >
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="ok()"
        >
          <feather-icon
            icon="SaveIcon"
            size="16"
            class="mr-50"
          />
          <span class="align-middle">Save</span>
        </b-button>
      </template>
    </b-modal>
    <!-- ./ create new category modal -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BImg, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BImg,
    BFormFile,

    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      fileSrc: '',
      image: null,
      name: '',
      sku: '',
      category: '',
      brand: '',
      minStock: '',
      nameError: 'Valid Name is required',
      nameValidation: false,
      skuError: 'Valid SKU is required',
      skuValidation: false,
      categoryError: 'Valid Category is required',
      categoryValidation: false,
      brandError: 'Valid Brand is required',
      brandValidation: false,
      minStockError: 'Minimum stock is required',
      minStockValidation: false,
      categories: [],
      inventoryItem: {},
      // category create object
      catName: '',
      remarks: '',
      status: 'active',
      catNameError: 'Valid name is required',
      catNameValidation: false,
      remarksError: 'Valid remarks is required',
      remarksValidation: false,
      statusError: 'Valid status is required',
      statusValidation: false,
      option: [{ title: 'Active', code: 'active' }, { title: 'Inactive', code: 'inactive' }],
      prevRoute: null,
      // validation rules
      required,
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // eslint-disable-next-line no-param-reassign
      vm.prevRoute = from
    })
  },
  beforeMount() {
    this.$http.get('inventory/category/respond-with/name-and-id')
      .then(response => {
        this.categories = response.data.categories ?? []

        // this.category = this.category[0]._id ?? ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  created() {
    this.$http.get(`inventory/item/${this.$route.params.id}`)
      .then(response => {
        this.inventoryItem = response.data
        this.fileSrc = response.data.image ?? null
        this.name = response.data.name ?? ''
        this.sku = response.data.sku ?? ''

        this.category = response.data.category ? response.data.category._id : ''
        this.brand = response.data.brand ?? ''
        this.minStock = response.data.minStock ?? ''
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    setImage() {
      if (this.image) {
        if (this.image && this.image.size <= 5242880) {
          const reader = new FileReader()
          reader.readAsDataURL(this.image)
          reader.onload = () => {
            this.fileSrc = reader.result
          }
        } else {
          this.fileSrc = null
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'File size not accepted more than 5MB',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    removeFileSrc() {
      this.fileSrc = null
      this.image = null
    },
    closeCreateNewCategoryModal() {
      this.$root.$emit('bv::hide::modal', 'modal-create-new-category', '')
    },
    submitCreateNewCategory(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.registerNewItemModalForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('name', this.catName)
          formData.append('remarks', this.remarks)
          formData.append('status', this.status)
          this.$http.post('inventory/category/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              this.closeCreateNewCategoryModal()
              const category = response.data.data
              this.categories.push({

                _id: category._id,
                name: category.name,
                status: category.status,
              })

              this.category = category._id
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || '',
                  icon: 'AlertTriangleIcon',
                  variant: 'success',
                },
              })
              // clear form data
              this.catName = ''
              this.remarks = ''
              this.status = 'active'
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'catName') {
                    this.catNameError = validationError.msg
                    this.catNameValidation = true
                  } else if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  } else if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
    cancelRedirect() {
      if (window.history.length > 1) {
        this.$router.back()
        // this.$router.push({ name: this.prevRoute.name, query: this.prevRoute.query })
      } else {
        this.$router.push({ name: 'inventory-inventory-items-list' })
      }
    },
    submitForm() {
      this.$refs.inventoryItemUpdateForm.validate().then(success => {
        if (success) {
          const cat = this.categories.find(o => o._id === this.category)
          const formData = new FormData()

          formData.append('name', this.name)
          formData.append('category', this.category)
          formData.append('categoryTitle', cat ? cat.name : '')
          formData.append('sku', this.sku)
          formData.append('brand', this.brand)
          formData.append('minStock', this.minStock)
          formData.append('image', this.fileSrc)
          this.$http.patch(`inventory/item/${this.$route.params.id}/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.$swal({
                title: 'Inventory Item Updated',
                html: '',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/success.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
                .then(result => {
                  if (result.value) {
                    if (window.history.length > 1) {
                      this.$router.back()
                      // this.$router.push({ name: this.prevRoute.name, query: this.prevRoute.query })
                    } else {
                      this.$router.push({ name: 'inventory-inventory-items-list' })
                    }
                  }
                })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'name') {
                    this.nameError = validationError.msg
                    this.nameValidation = true
                  } else if (validationError.param === 'category') {
                    this.categoryError = validationError.msg
                    this.categoryValidation = true
                  } else if (validationError.param === 'sku') {
                    this.skuError = validationError.msg
                    this.skuValidation = true
                  } else if (validationError.param === 'brand') {
                    this.brandError = validationError.msg
                    this.brandValidation = true
                  } else if (validationError.param === 'minStock') {
                    this.minStockError = validationError.msg
                    this.minStockValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>

<style scoped>
  .step-class {
    color: #104D9D;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
  }
  .img-preview-block{
      box-sizing: border-box;
      width: 100px;
      height: 100px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }
</style>
